// @flow
import * as React from 'react';
import { Image, Heading } from '@4alldigital/react-ui-components';
import { isMobile } from 'react-device-detect';
import { Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';
import styles from './Intro.module.scss';
import Header from '../Header';
import { intro } from '../../_data';

const Intro = (): React.Node => {
  console.info('isMobile', isMobile);
  const duration = '100%';
  const durationImages = '80%';
  const showIndicators = false;

  if (isMobile) {
    return (
      <div className={styles.layout}>
        <Header />
        <div>
          <div className={styles.imageWrapper}>
            <div className={styles.introImage}>
              <Image src={intro.images.imac} />
            </div>

            <div className={styles.introImage}>
              <Image src={intro.images.macbook} />
            </div>

            <div className={styles.introImage}>
              <Image src={intro.images.ipad} />
            </div>

            <div className={styles.introImage}>
              <Image src={intro.images.iphone} />
            </div>
          </div>
          <div className={styles.copyPosition}>
            <div className={styles.copyWrapper}>
              <div className={styles.copy}>
                <Heading tag="h1" className={styles.heading}>
                  {intro.title}
                </Heading>
                <div className={styles.summary}>
                  <p>{intro.summary}</p>
                </div>
              </div>
            </div>
            <div className={styles.taglinePosition}>
              <div className={styles.copyWrapper}>
                <div className={styles.copy}>
                  <Heading tag="h2" className={styles.tagline}>
                    {intro.tagline}
                  </Heading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <Scene duration={duration} pin indicators={showIndicators} triggerHook={0}>
      <div className={styles.layout}>
        <Header />
        <div>
          <div className={styles.imageWrapper}>
            <Scene
              triggerHook={0}
              duration={durationImages}
              triggerElement="#trigger"
            >
              <Tween
                from={{ scale: 2, left: '250px' }}
                to={{ scale: 1, left: '0px' }}
                paused
              >
                <div className={styles.introImage}>
                  <Image src={intro.images.imac} />
                </div>
              </Tween>
            </Scene>
            <Scene
              triggerHook={0}
              duration={durationImages}
              triggerElement="#trigger"
            >
              <Tween
                from={{ scale: 2.5, left: '200px' }}
                to={{ scale: 1, left: '0px' }}
                paused
              >
                <div className={styles.introImage}>
                  <Image src={intro.images.macbook} />
                </div>
              </Tween>
            </Scene>
            <Scene
              triggerHook={0}
              duration={durationImages}
              triggerElement="#trigger"
            >
              <Tween
                from={{ scale: 1.5, right: '100px' }}
                to={{ scale: 1, right: '0px' }}
                paused
              >
                <div className={styles.introImage}>
                  <Image src={intro.images.ipad} />
                </div>
              </Tween>
            </Scene>
            <Scene
              triggerHook={0}
              duration={durationImages}
              triggerElement="#trigger"
            >
              <Tween
                from={{ scale: 2.8, right: '250px' }}
                to={{ scale: 1, right: '0px' }}
                paused
              >
                <div className={styles.introImage}>
                  <Image src={intro.images.iphone} />
                </div>
              </Tween>
            </Scene>
          </div>
          <div className={styles.copyPosition}>
            <div className={styles.copyWrapper}>
              <Scene
                triggerHook={0}
                duration={duration}
                triggerElement="#trigger"
              >
                <Tween from={{ opacity: 1 }} to={{ opacity: 0 }} paused>
                  <div className={styles.copy}>
                    <Heading tag="h1" className={styles.heading}>
                      {intro.title}
                    </Heading>
                    <div className={styles.summary}>
                      <p>{intro.summary}</p>
                    </div>
                  </div>
                </Tween>
              </Scene>
            </div>
            <div className={styles.taglinePosition}>
              <div className={styles.copyWrapper}>
                <Scene
                  triggerHook={0}
                  duration={duration}
                  triggerElement="#trigger"
                >
                  <Tween
                    from={{ opacity: 0, top: 0 }}
                    to={{ opacity: 1, top: '200px' }}
                    paused
                  >
                    <div className={styles.copy}>
                      <Heading tag="h2" className={styles.tagline}>
                        {intro.tagline}
                      </Heading>
                    </div>
                  </Tween>
                </Scene>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Scene>
  );
};

export default Intro;
