// @flow
import * as React from 'react';
import { Container } from '@4alldigital/react-ui-components';
// Import FooterMenu from '../FooterMenu';
// Import HavingTrouble from '../HavingTrouble';
import SocialNav from '../SocialNav';
import styles from './Footer.module.scss';

const Footer = () => {
  const COPYRIGHT_TEXT = '© {year} OTT app. All rights reserved.';

  return (
    <footer className={styles.footer}>
      <Container constrained="default">
        <div className={styles.layout}>
          <div className={styles.footerHeading}>
            <SocialNav />
          </div>
        </div>
      </Container>
      <Container constrained="default">
        <div className={styles.footerBottom}>
          {/* <div>
            <FooterMenu />
          </div>
          <div>
            <HavingTrouble />
          </div> */}
        </div>
        <div className={styles.copyright}>
          <span>
            {COPYRIGHT_TEXT.replace('{year}', new Date().getFullYear()).trim()}
          </span>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
