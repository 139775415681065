// @flow
import * as React from 'react';
import { Container, Heading } from '@4alldigital/react-ui-components';
import HubspotForm from 'react-hubspot-form';
import styles from './HubspotForm.module.scss';

const Form = (): React.Node => {
  return (
    <Container constrained="small">
      <Heading tag="h3" className={styles.heading}>
        Get in touch
      </Heading>
      <div className={styles.layout}>
        <div className={styles.form}>
          <HubspotForm
            portalId="25485639"
            formId="feaab52c-3808-42e3-a392-921efbfc50a9"
            region="eu1"
            onSubmit={() => console.log('Submit!')}
            onReady={() => console.log('Form ready!')}
            loading={<div>Loading...</div>}
          />
        </div>
      </div>
    </Container>
  );
};

export default Form;
