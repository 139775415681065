// @flow
import * as React from 'react';
import { Container } from '@4alldigital/react-ui-components';
import { Helmet } from 'react-helmet';
// Import Header from '../Header';
import styles from './Page.module.scss';
import { BASE_URL } from '../../constants';

type Props = {
  children: React.Node,
};

const Page = ({ children }: Props): React.Node => {
  const META = {
    TITLE: 'OST App',
    DESCRIPTION: 'Linear & On-Demand streaming platform',
  };
  return (
    <>
      <Helmet>
        <title>{META.TITLE}</title>
        <meta name="description" content={META.DESCRIPTION} />
        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link rel="canonical" href={BASE_URL} />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:type" content="og:product" />
        <meta property="og:title" content={META.TITLE} />
        <meta property="og:description" content={META.DESCRIPTION} />
        <meta property="og:site_name" content={META.TITLE} />
        <meta property="og:image" content={`${BASE_URL}/images/logo.svg`} />
        <meta property="og:image:width" content="1960" />
        <meta property="og:image:height" content="1276" />
      </Helmet>
      {/* <Header /> */}
      <main className="root-content">
        <section id="page" className={styles.page}>
          <Container constrained="default">{children}</Container>
        </section>
      </main>
    </>
  );
};

export default Page;
