// @flow
import * as React from 'react';
import { Parallax } from 'react-scroll-parallax';

type Props = {
  children: string,
  y: Array<number>,
  x: Array<number>,
};

const ParallaxObject = ({ children, x, y }: Props): React.Node => (
  <Parallax translateY={y} translateX={x} tagOuter="div">
    {children}
  </Parallax>
);

ParallaxObject.defaultProps = {
  y: [0, 0],
  x: [0, 0],
};

export default ParallaxObject;
