// @flow
import * as React from 'react';
import { Parallax } from 'react-scroll-parallax';
import styles from './ParallaxHorizontal.module.scss';

type Props = {
  children: React.Node,
  inverted: boolean,
};

const ParallaxHorizontal = ({ children, inverted }: Props): React.Node => {
  const value = inverted ? [5, 0] : [-5, 0];
  return (
    <Parallax className={styles.parallax} translateX={value} tagOuter="div">
      {children}
    </Parallax>
  );
};

export default ParallaxHorizontal;
