// @flow
import * as React from 'react';
import { Heading, Container } from '@4alldigital/react-ui-components';
import styles from './AboutUs.module.scss';

const AboutUs = (): React.Node => {
  return (
    <Container constrained="small">
      <div className={styles.layout}>
        <Heading tag="h3" className={styles.heading}>
          Meet Liam and Joe
        </Heading>
        <p>
          A creative duo that loves to learn, collaborate and develop purposeful
          solutions. Both have worked within the Media and Technology realm for
          many years, and seen simple problems over engineered and complex
          process put in place. They have both witness how poor design and
          execution can waste a huge amount of time and money unnecessarily.
        </p>
        <p>
          Heading up the Digital space within NBCUniversal International, they
          have proven that rapid prototyping and carefully planning of systems;
          while working with the clients & stakeholders are key to understanding
          product, the process and the design of the final solution. They have
          taken this knowledge, and the skills and know-how from the last 20
          years plus to help smaller companies achieve the unachievable.
        </p>
      </div>
    </Container>
  );
};

export default AboutUs;
