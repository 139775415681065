// @flow
import * as React from 'react';
import { v4 as uuid } from 'uuid';
import { Link, Instagram } from '@4alldigital/react-ui-components/';
import styles from './SocialNav.module.scss';

type Props = {
  color: string,
};

const SocialNav = ({ color }: Props): React.Node => {
  const iconsSize = 38;
  const links = [
    {
      href: 'https://instagram.com/ost-app',
      label: 'Instagram',
      icon: <Instagram color={color} size={iconsSize} />,
    },
  ];

  return (
    <nav id="navigation" className={styles.nav}>
      {links &&
        links.map(link => (
          <div className={styles.navItem} key={uuid()}>
            <Link className={styles.navLink} href={link.href} target="_blank">
              {link.icon}
              {link.label}
            </Link>
          </div>
        ))}
    </nav>
  );
};

SocialNav.defaultProps = {
  color: '#FFF',
};

export default SocialNav;
