export const intro = {
  title: 'OST Platform',
  summary:
    'A secure, fast and high quality streaming service for live and on-demand video content.',
  tagline: 'Anytime.  Anywhere.  Any Device.',
  images: {
    imac: 'https://ost-static-assets.s3.eu-west-1.amazonaws.com/images/imac-min.png',
    macbook:
      'https://ost-static-assets.s3.eu-west-1.amazonaws.com/images/macbook-min.png',
    ipad: 'https://ost-static-assets.s3.eu-west-1.amazonaws.com/images/ipad-min.png',
    iphone:
      'https://ost-static-assets.s3.eu-west-1.amazonaws.com/images/iphone-min.png',
  },
};

export const sections = [
  {
    title: 'The Experience',
    images: [
      'https://ost-static-assets.s3.eu-west-1.amazonaws.com/images/panel1-650-min.png',
    ],
    details:
      '<p>Reach users at a global scale while ensuring your content is being streamed securely, fast and to the highest quality. We utilise the best online video encoding by Amazon AWS.</p><p>Whether you decide to offer Video on Demand or Stream Live directly to your users - the choice is yours! OST provides an uninterrupted Secure Live stream delivering a reliable Live stream with minimum buffering to users on multiple devices.</p><p>Not enough? We can integrate your latest podcast, articles, blogs and other media services, providing a more comprehensive offering to your users - all in one place.</p>',
    points: null,
  },
  {
    title: 'Connecting your Users to your Content with the way you intended.',
    images: [
      'https://ost-static-assets.s3.eu-west-1.amazonaws.com/images/cms2-min.png',
    ],
    details:
      '<p>Create, store, and archive all your content in a single place. An easy to use Content Management System allowing you to publish your content exactly how you intended without restrictions or unfair policies. </p><p>From Video on Demand, to Live streaming and catchup - We do the heavy lifting so that you don’t.</p>',
    points: null,
  },
  {
    title: 'Accelerate time to market with easy setup and integration',
    images: [
      'https://ost-static-assets.s3.eu-west-1.amazonaws.com/images/aws-services-min.png',
    ],
    details:
      'Is your source content in multiple sources or stored in another repository? We can use our easy connect service to import content directly into our system. We can aid in supporting additional metadata by using our AI & ML (Artificial Intelligence & Machine Learning) to create synopsis and transcript data directly from your video content.',
    points: null,
  },
  {
    title: 'Fully Branded and customizable ',
    images: [
      'https://ost-static-assets.s3.eu-west-1.amazonaws.com/images/brand1-min.jpg',
      'https://ost-static-assets.s3.eu-west-1.amazonaws.com/images/brand2-min.jpg',
      'https://ost-static-assets.s3.eu-west-1.amazonaws.com/images/brand3-min.jpg',
    ],
    details:
      'Utilise our flexible frontend to design your website and Apps to suit your content; Whether it be Fitness & Health or News & Finance to binge worthy shows or vlogs, we have customisable themes to suit your content type.',
    points: null,
  },
  {
    title: 'Diversify your revenue',
    images: [
      'https://ost-static-assets.s3.eu-west-1.amazonaws.com/images/finance1-min.png',
    ],
    details:
      'Take advantage of your valued content by monetising correctly, creating a premium offering to your users. The choice is yours:',
    points: [
      'Subscription based',
      'One off payment / Flexible Payment',
      'Advertise on Live Stream, VOD, and within the App & Website',
      'Premium content separation',
    ],
  },
  {
    title: 'Informed Decision-making',
    images: [
      'https://ost-static-assets.s3.eu-west-1.amazonaws.com/images/analytics1-min.jpg',
      'https://ost-static-assets.s3.eu-west-1.amazonaws.com/images/analytics2-min.jpg',
      'https://ost-static-assets.s3.eu-west-1.amazonaws.com/images/analytics3-min.jpg',
    ],
    details:
      'Analyst real-time data on audience interest and usage. Easily connect to an array of analytical tools like Elastic, Google Analytics, Conviva or to your favourite heat mapping tool.',
    points: null,
  },
  {
    title: 'Content migration',
    images: [
      'https://ost-static-assets.s3.eu-west-1.amazonaws.com/images/network1-min.png',
    ],
    details: null,
    points: [
      'Secure Streaming with AES or DRM',
      'Fast & Reliable end to end Streaming, utilising multi-CDNs',
      '24/7 Monitoring of Service',
      'Amazon AWS backed services',
      'Optimised back-end with easy integration to 3rd parties',
    ],
  },
];
