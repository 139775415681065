// @flow
import * as React from 'react';
import cx from 'classnames';
import { Container } from '@4alldigital/react-ui-components';
import Logo from '../Logo';
import styles from './Header.module.scss';

type Props = {
  overlayHeader: boolean,
};

const Header = ({ overlayHeader }: Props): React.Node => {
  const ref = React.useRef(null);

  return (
    <div
      className={cx({
        [styles.spacedHeader]: !overlayHeader,
      })}
      ref={ref}
    >
      <header className={styles.headerWrapper}>
        <Container constrained="default">
          <div className={styles.header}>
            <div />
            <div className={cx(styles.logo)}>
              <Logo />
            </div>
            <div />
          </div>
        </Container>
      </header>
    </div>
  );
};

export default Header;
