// @flow
import * as React from 'react';
import cx from 'classnames';
import { ChevronDown } from '@4alldigital/react-ui-components';
import { Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';
import styles from './ScrollDown.module.scss';

const ScrollDown = (): React.Node => {
  return (
    <div className={styles.layout}>
      <div className={styles.inner}>
        <Scene triggerHook={0} duration={300} triggerElement="#trigger">
          <Tween from={{ opacity: 1 }} to={{ opacity: 0 }} paused>
            <div className={cx(styles.arrow, styles.bounce)}>
              <ChevronDown color="#FFF" size={50} />
            </div>
          </Tween>
        </Scene>
      </div>
    </div>
  );
};

export default ScrollDown;
