// @flow
import * as React from 'react';
import cx from 'classnames';
import { Parallax } from 'react-scroll-parallax';
import styles from './ShapeField.module.scss';
import { ReactComponent as Cluster01Rect } from './cluster-01-rect.svg';
import { ReactComponent as Cluster04Square } from './cluster-04-square.svg';

type Props = {
  variant: 'alpha' | 'beta' | 'gamma',
};

const ShapeField = ({ variant }: Props): React.Node => {
  return (
    <div className={cx(styles.root)}>
      <div className={styles.shapeCluster01}>
        <Parallax
          translateY={[100, -100]}
          className={styles['hemi-left']}
          slowerScrollRate
        >
          <Cluster01Rect />
        </Parallax>
        <Cluster04Square className={styles['rect']} />
        <Parallax translateY={[-100, 100]} className={styles['hemi-right']}>
          <Cluster01Rect className={styles[variant]} />
        </Parallax>
      </div>
      <div className={styles.shapeCluster02}>
        <Cluster04Square className={styles['hemi']} />
        <Parallax
          offsetYMax={30}
          offsetYMin={-30}
          className={styles['triangle-big']}
        >
          <Cluster01Rect className={styles[variant]} />
        </Parallax>
        <Parallax
          offsetYMax={30}
          offsetYMin={-30}
          translateY={[30, -30]}
          className={styles['triangle-small']}
          slowerScrollRate
        >
          <Cluster04Square />
        </Parallax>
      </div>
      <div className={styles.shapeCluster03}>
        <Parallax
          offsetYMax={60}
          offsetYMin={-20}
          translateY={[60, -20]}
          className={styles['triangle-top']}
          slowerScrollRate
        >
          <Cluster01Rect className={styles[variant]} />
        </Parallax>

        <Cluster04Square className={styles['triangle-top']} />
        <Parallax
          offsetYMax={60}
          offsetYMin={-60}
          translateY={[60, -60]}
          className={styles['triangle-big']}
        >
          <Cluster01Rect className={styles[variant]} />
        </Parallax>
      </div>
      <div className={styles.shapeCluster04}>
        <Cluster04Square className={styles['square']} />
        <Parallax
          offsetYMax={60}
          offsetYMin={-60}
          translateY={[60, -60]}
          className={styles['triangle']}
        >
          <Cluster01Rect className={styles[variant]} />
        </Parallax>
        <Parallax
          offsetYMax={30}
          offsetYMin={-30}
          translateY={[30, -30]}
          className={styles['hemi-right']}
        >
          <Cluster04Square />
        </Parallax>
      </div>
    </div>
  );
};

export default ShapeField;
