// @flow
import * as React from 'react';
import {
  Heading,
  List,
  ListItem,
  Image,
} from '@4alldigital/react-ui-components';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import cx from 'classnames';
import renderHTML from 'html-react-parser';
import ParallaxObject from '../ParallaxObject';
import ParallaxHorizontal from '../ParallaxHorizontal';
import styles from './Section.module.scss';

type ItemTypes = {
  title: string,
  image: string,
  details: string,
  points: Array<string>,
};

type Props = {
  item: Array<ItemTypes>,
  inverted: boolean,
};

const Section = ({ item, inverted }: Props): React.Node => {
  const direction = inverted ? styles.ltr : styles.rtl;
  // Const imageY = [0, -20];
  const isBoxed = item.images.length > 1;

  const imageRender = images => {
    if (isMobile) {
      return (
        <div className={styles.imageOuter}>
          {images.map((image, key) => {
            const depth = isMobileOnly ? 30 : 60;
            const temp = {
              top: `${depth * key}px`,
              right: `${100 * key}px`,
              // ZIndex: key * -key,
            };
            return (
              <div style={temp} className={cx(styles.imageWrapper)} key={key}>
                <div
                  className={cx({
                    [styles.imageBoxed]: isBoxed,
                  })}
                >
                  <Image src={image} />
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    return (
      <div className={styles.imageOuter}>
        {images.map((image, key) => {
          const temp = {
            top: `${100 * key}px`,
            right: `${100 * key}px`,
          };
          const val = -20 * key + 1;
          const tempImageY = [0, val];
          return (
            <div style={temp} className={cx(styles.imageWrapper)} key={key}>
              <ParallaxObject y={tempImageY}>
                <div
                  className={cx({
                    [styles.imageBoxed]: isBoxed,
                  })}
                >
                  <Image src={image} />
                </div>
              </ParallaxObject>
            </div>
          );
        })}
      </div>
    );
  };

  if (isMobile) {
    return (
      <div className={styles.outer}>
        <div className={styles.inner}>
          {inverted && isTablet && imageRender(item.images)}
          <div className={direction}>
            {item.title && <Heading tag="h1">{item.title}</Heading>}
            {item.details && renderHTML(item.details)}
            <div className={styles.points}>
              {item.points && (
                <List>
                  {item.points.map((point, index) => {
                    return (
                      <ListItem key={index} className={styles.listItem}>
                        {point}
                      </ListItem>
                    );
                  })}
                </List>
              )}
            </div>
          </div>
          {(!inverted || isMobileOnly) && imageRender(item.images)}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.outer}>
      <div className={styles.inner}>
        {inverted && imageRender(item.images)}
        <ParallaxHorizontal inverted={inverted}>
          <div className={direction}>
            {item.title && <Heading tag="h1">{item.title}</Heading>}
            {item.details && renderHTML(item.details)}
            <div className={styles.points}>
              {item.points && (
                <List>
                  {item.points.map((point, index) => {
                    return (
                      <ListItem key={index} className={styles.listItem}>
                        {point}
                      </ListItem>
                    );
                  })}
                </List>
              )}
            </div>
          </div>
        </ParallaxHorizontal>
        {!inverted && imageRender(item.images)}
      </div>
    </div>
  );
};

Section.defaultProps = {
  inverted: false,
};

export default Section;
