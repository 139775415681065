// @flow
import * as React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import { ToastContainer, Slide } from 'react-toastify';
// Import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import 'react-toastify/dist/ReactToastify.min.css';
import 'ruiCss';
import Page from './components/Page';
import Section from './components/Section';
import styles from './App.module.scss';
import Footer from './components/Footer';
import ShapeField from './components/ShapeField';
import { sections } from './_data';
import { Controller } from 'react-scrollmagic';
import Intro from './components/Intro';
import ScrollDown from './components/ScrollDown';
import AboutUs from './components/AboutUs';
// Import ContactForm from './components/ContactForm';
import HubspotForm from './components/HubspotForm';

const App = (): React.Node => {
  return (
    <ParallaxProvider>
      <div id="trigger" />
      <Page>
        <div className={styles.patterns}>
          <ShapeField variant="alpha" />
          <ShapeField variant="beta" />
          <ShapeField variant="gamma" />
          <ShapeField variant="alpha" />
          <ShapeField variant="beta" />
          <ShapeField variant="gamma" />
        </div>
        <div className={styles.intro}>
          <Controller>
            <Intro />
          </Controller>
        </div>
        <div className={styles.sections}>
          {sections.map((item, index) => {
            const isOdd = index % 2 ? true : false;
            return <Section key={index} item={item} inverted={isOdd} />;
          })}
        </div>
        <div className={styles.section}>
          <AboutUs />
        </div>
        <div className={styles.section}>
          {/* <GoogleReCaptchaProvider reCaptchaKey="6Ld0TSIeAAAAAHNPsSceZG245FYYtfjn45Cbr5LY"> */}
          <HubspotForm />
          {/* </GoogleReCaptchaProvider> */}
        </div>
      </Page>
      <Controller>
        <ScrollDown />
      </Controller>
      <Footer />
      <ToastContainer
        className={styles.toastcontainer}
        autoClose={5000}
        transition={Slide}
        hideProgressBar={true}
        pauseOnFocusLoss
        closeOnClick
        pauseOnHover
      />
    </ParallaxProvider>
  );
};

export default App;
